export const productList = {
  "Black Edition":
  {
    productType: "Black Edition",
    image: "black_edition.png",
    headerLine1: "Elegance in Every",
    headerLine2: "Thread of Black Edition",
    description: "Experience the epitome of sophistication and refinement with our exclusive collection. We believe that your\
      sleep environment should reflect your unique taste. Our Black Edition is meticulously crafted to provide you\
      with the ultimate in luxury, comfort and style. Each mattress in this exquisite collection is a true work of\
      art, designed to seamlessly blend aesthetics and functionality.",
    products: [
      {
        "video":"Naturapedic.mp4",
        "image": "black_edition1.png",
        "headerTitle": "Naturapedic Mattress",
        "shortDescriptionLine1":"Sink into the soothing, natural embrace of bamboo ",
        "shortDescriptionLine2":"fibers every night with a connection to nature.",
        "headerDescription": "Experience the Naturapedic Bamboo Mattress - a touch of nature in your bedroom. Sink into the\
          soothing, natural embrace of bamboo fibers every night. This sustainable choice not only offers\
          supreme comfort but also a connection to the earth beneath you. Sleep soundly on the harmony of\
          nature and comfort combined.",
        "detailTitle": "Complete eco-friendly choice for a restful and supportive night’s sleep",
        "detailDescription": "Tailored for those experiencing back and joint pain, our specially designed mattress offers gentle cushioning and buoyant support providing an everyday remedy to relieve pain. Crafted with 100% organic latex, this Naturapedic mattress not only ensures a cool sleeping experience but also promotes spine support.",
        "features": [{
          "image": "spine.svg",
          "titleLine1": "Works on Back",
          "titleLine2": "and Joint Pain"
        }, {
          "image": "img_frame_19_blue_gray_400.svg",
          "titleLine1": "Organic",
          "titleLine2": "Bamboo Fabric"
        },
        {
          "image": "sustain.svg",
          "titleLine1": "Sustainable",
          "titleLine2": "Material"
        },
        {
          "image": "img_frame_19_blue_gray_400_44x74.svg",
          "titleLine1": "Ultrasoft",
          "titleLine2": "Material"
        },
        {
          "image": "ortho.svg",
          "titleLine1": "Orthopedic",
          "titleLine2": "Support"
        }
        ],
        "images": ["img_36476_white_zoom_6.png", "img_outdoor_sofa_set_2.png", "img_stuart_sofa_1.png", "img_maya_sofa_three.png"],
        "footerImage":"NA",
      },
      {
        "video":"ViscoFlex.mp4",
        "image": "black_edition2.png",
        "headerTitle": "Viscoflex Mattress",
        "headerDescription": "Introducing the ViscoFlex Mattress, where advanced pressure-relieving technology meets perfect posture support. Experience the ultimate in comfort as this mattress conforms to your body, alleviating pressure points and ensuring optimal spinal alignment. Enjoy restful nights and wake up refreshed, knowing that your sleep is well taken care of.",
        "shortDescriptionLine1":"Experience the ultimate in comfort as this mattress",
        "shortDescriptionLine2":" conforms to your body and ensuring spinal alignment",
        "detailTitle": "Precision engineered for orthopaedic benefits",
        "detailDescription": "Our mattress is designed to provide exceptional support to your spine, neck and shoulders. With the inclusion of memory foam at the core, it ensures an enhanced sleeping experience. Revel in orthopaedic support, hypoallergenic features, cool nights and the luxury of memory foam- a perfect combination for a revitalizing and comfortable night’s sleep.",
        "features": [{
          "image": "ortho.svg",
          "titleLine1": "Orthopedic",
          "titleLine2": "Support"
        }, {
          "image": "img_frame_19.svg",
          "titleLine1": "Naturally",
          "titleLine2": "Cooling"
        },
        {
          "image": "pressure_relieve.svg",
          "titleLine1": "Pressure Relieving",
          "titleLine2": "Technology"
        },
        {
          "image": "img_frame_19_blue_gray_400_44x74.svg",
          "titleLine1": "Ultrasoft",
          "titleLine2": "Material"
        },
        {
          "image": "vector.svg",
          "titleLine1": "Hypo-",
          "titleLine2": "allergenic"
        }
        ],
        "images": ["viscoflex1.png", "viscoflex2.png", "viscoflex3.png", "viscoflex4.png"],
        "footerImage":"NA",
      },
      {
        "video":"Memory.mp4",
        "image": "black_edition3.png",
        "headerTitle": "Memory Plus Mattress",
        "headerDescription": "Discover the Memory Plus Mattress, your ticket to unparalleled comfort. Engineered for the even distribution of body weight, this mattress ensures every inch of your body is cradled in blissful support. Say goodbye to overheating with its naturally cooling technology, and embrace restful, cool nights of sleep.",
        "shortDescriptionLine1":"Engineered for the even distribution of body",
        "shortDescriptionLine2":" weight ensuring rest to every inch of your body",
        "detailTitle": "Precision engineered for orthopaedic benefits",
        "detailDescription": "Our mattress is designed to provide exceptional support to your spine, neck and shoulders. With the inclusion of memory foam at thecore, it ensures an enhanced sleeping experience. Revel in orthopaedic support, hypoallergenic features, cool nights and the luxury of memory foam- a perfect combination for a revitalizing and comfortable night’s sleep.",
        "features": [{
          "image": "contouring.svg",
          "titleLine1": "Contouring",
          "titleLine2": "Memory Foam"
        }, {
          "image": "img_frame_19.svg",
          "titleLine1": "Naturally",
          "titleLine2": "Cooling"
        },
        {
          "image": "pressure_relieve.svg",
          "titleLine1": "Pressure Relieving",
          "titleLine2": "Technology"
        },
        {
          "image": "spine.svg",
          "titleLine1": "Proper Spine",
          "titleLine2": "Alignment"
        },
        {
          "image": "disturbance.svg",
          "titleLine1": "No Partner",
          "titleLine2": "Disturbance"
        }
        ],
        "images": ["memory1.png", "memory2.png", "memory3.png", "memory4.png"],
        "footerImage":"NA",
      },
      {
        "video":"ProX.mp4",
        "image": "black_edition4.png",
        "headerTitle": "Pro-X Mattress",
        "headerDescription": "Experience the Pro-X Mattress where softness meets smart technology. Sink into the embrace of ultrasoft foam material that molds to your body for unmatched comfort. Stay cool and dry with its cutting-edge moisture absorption technology, ensuring a refreshing night’s sleep every time you lay down. Elevate your sleep with Pro-X for the perfect blend of softness and dryness.",
        "shortDescriptionLine1":"Stay cool with its moisture absorption",
        "shortDescriptionLine2": " technology, ensuring a refreshing night’s sleep",
        "detailTitle": "Revitalizing your nights with a perfect balance of comfort and support",
        "detailDescription": "Our specially engineered mattress offers a cool and comforting sensation to ease away the strains of the day. Designed for optimal joint and back support, it ensures you wake up feeling fresher and ready to embrace the day ahead. Elevate your sleep experience with a perfect balance of comfort and support, making every night a rejuvenating escape.",
        "features": [{
          "image": "spine.svg",
          "titleLine1": "Beneficial for",
          "titleLine2": "Joint & Back Pain"
        }, {
          "image": "deepsleep.svg",
          "titleLine1": "Deep Sleep",
          "titleLine2": "Experience"
        },
        {
          "image": "img_frame_19_blue_gray_400_44x74.svg",
          "titleLine1": "Breathable",
          "titleLine2": "Mattress"
        },
        {
          "image": "air.svg",
          "titleLine1": "Promotes",
          "titleLine2": "required air-flow"
        },
        {
          "image": "img_frame_19_blue_gray_400_44x64.svg",
          "titleLine1": "Moisture",
          "titleLine2": "Absorption"
        }
        ],
        "images": ["prox1.png", "prox2.png", "prox3.png", "prox4.png"],
        "footerImage":"NA",
      },
    ],
    reasons: [
      {
        title: "Ageless Orthopedic Support for good posture control",
        reason: "Experience unparalleled support and comfort with our mattresses, designed to provide superior\
          orthopedic backing and posture control for all ages. Wake up refreshed and pain-free, every\
          day",
        image: "black_edition_reason1.png",
      },
      {
        title: "Sustainable and Hypoallergenic Fabric",
        reason: "Sustainable and hypoallergenic fabrics combine eco-friendliness with year-round comfort. They\
          offer breathability and allergy relief, ensuring a cool, airy feel while being gentle on both\
          skin and the environment.",
        image: "img_fcf037cde48a756f_upscale_312x391.png",
      },
      {
        title: "Special Pressure Relief Technology Mattress",
        reason: "Experience unparalleled comfort with our cutting-edge mattress, designed to distribute weight\
          evenly and alleviate pressure points for a restful sleep.",
        image: "img_fcf037cde48a756f_upscale_278x422.png",
      }
    ],
    faqs: [
      {
        question: "What is Black Edition?",
        answer: "Something New"
      },
      {
        question: "What is the 100-nights trial?",
        answer: "Something"
      },
      {
        question: "What kind of bedframe works with a Durafit mattress?",
        answer: "Something"
      },
      {
        question: "How is the material sustainable?",
        answer: "Something"
      }
    ]
  },
  "Mattress":
  {
    productType: "Mattress",
    image: "up-in-the-clouds_1024x1024_1.png",
    headerLine1: "Selling Sleep,",
    headerLine2: "not just Mattress",
    description: "At Durafit, we understand that a good night's sleep is essential for your overall well-being. That's why we have meticulously designed each mattress in our collection to provide you with the perfect balance of support and relaxation. Our mattresses are more than just a place to lay your head; they are your ticket to uninterrupted and restful sleep",
    products: [
      {
        "video":"Ambition_plus.mp4",
        "image": "mattress1.png",
        "headerTitle": "Ambition Plus Mattress",
        "shortDescriptionLine1":"Experience superior posture support as you ",
        "shortDescriptionLine2":"sleep, making every night's rest comfortable.",
        "headerDescription": "Ambition Plus Mattress where sustainability meets enduring comfort. Crafted from sustainable and durable materials, it's designed to stand the test of time. Experience superior posture support as you sleep, making every night's rest a step towards your ambitions. Choose Ambition Plus for a sustainable, long-lasting, and supportive sleep solution.",
        "detailTitle": "Experience a more deeper, soundful and rejuvenating sleep",
        "detailDescription": "Luxurious cushioning materials for perfect back support and extra-soft surface treatments creating a plusher, more comfortable feel. This mattress is designed to make luxurious sleep an affordable deal. Built with advanced Unicell Technology which gives the right amount of support your body needs during the night.",
        "features": [{
          "image": "unicell.svg",
          "titleLine1": "Unicell",
          "titleLine2": "Technology"
        }, {
          "image": "spine.svg",
          "titleLine1": "Posture",
          "titleLine2": "Support"
        },
        {
          "image": "sustain.svg",
          "titleLine1": "Sustainable",
          "titleLine2": "Material"
        },
        {
          "image": "pressure_relieve.svg",
          "titleLine1": "Pressure Relieving",
          "titleLine2": "Technology"
        },
        {
          "image": "money.svg",
          "titleLine1": "Affordable",
          "titleLine2": "Luxury"
        }
        ],
        "images": ["ambition4.png", "ambition1.png", "ambition2.png", "ambition3.png"],
        "footerImage":"NA",
      },
      {
        "video":"DuraSoft.mp4",
        "image": "mattress2.png",
        "headerTitle": "Durasoft Mattress",
        "shortDescriptionLine1":"Crafted with high-density Vital Bond technology, ",
        "shortDescriptionLine2":"this mattress guarantees long-lasting support.",
        "headerDescription": "Introducing the Durasoft Mattress, your gateway to optimum comfort and unparalleled durability. Crafted with high-density Vital Bond technology, this mattress guarantees long-lasting support and resilience. Experience a night of undisturbed, luxurious comfort as you sleep soundly on Durasoft.",
        "detailTitle": "Advanced mattress packed with latest sleep innovation technologies",
        "detailDescription": "DuraSoft mattress range of collection will gently support your body at all points and transform your spine to a good standing posture. It is made to pay special attention to shoulders, hips and lower back- the heaviest parts of the human body.",
        "features": [{
          "image": "pressure_relieve.svg",
          "titleLine1": "High density",
          "titleLine2": "Vital bond"
        }, {
          "image": "spine.svg",
          "titleLine1": "Orthopedic",
          "titleLine2": "Spine Support"
        },
        {
          "image": "contouring.svg",
          "titleLine1": "Maintained",
          "titleLine2": "firmness"
        },
        {
          "image": "air.svg",
          "titleLine1": "Rebonded",
          "titleLine2": "Foam Technology"
        },
        {
          "image": "contouring.svg",
          "titleLine1": "High Density ",
          "titleLine2": "Firm Core"
        }
        ],
        "images": ["durasoft4.png","durasoft1.png", "durasoft2.png", "durasoft3.png"],
        "footerImage":"NA",
      },
      {
        "video":"2in1.mp4",
        "image": "mattress3.png",
        "headerTitle": "2 in 1 Mattress",
        "shortDescriptionLine1":"Designed for proper posture and equipped with",
        "shortDescriptionLine2":"advanced pressure-relieving technology",
        "headerDescription": "Our 2-in-1 Mattress – the key to better sleep. Designed for proper posture and equipped with advanced pressure-relieving technology, it's the ultimate sleep solution. Experience the perfect balance of support and comfort, ensuring you wake up refreshed and ready to take on the day. Say goodbye to discomfort and hello to your best night's sleep ever with our 2-in-1 Mattress.",
        "detailTitle": "Mattress that keeps your night cool and give zero partner disturbance",
        "detailDescription": "Gentle support for your body at all points while keeping your spine in the same shape as a person with good standing position. It is made to pay special attention to shoulders, hips and lower back- the heaviest parts of the human body.",
        "features": [{
          "image": "spine.svg",
          "titleLine1": "Optimum",
          "titleLine2": "Back Support"
        }, {
          "image": "ortho.svg",
          "titleLine1": "Attention to hips,",
          "titleLine2": "shoulder & back"
        },
        {
          "image": "img_frame_19_blue_gray_400_44x74.svg",
          "titleLine1": "Extra",
          "titleLine2": "comfort"
        },
        {
          "image": "pressure_relieve.svg",
          "titleLine1": "Pressure relieving",
          "titleLine2": "technology"
        },
        {
          "image": "deepsleep.svg",
          "titleLine1": "Dual Firm",
          "titleLine2": "Technology"
        }
        ],
        "images": ["twoinone1.png", "twoinone2.png", "twoinone3.png", "twoinone4.png"],
        "footerImage":"NA",
      },
      {
        "video":"2in1plus.mp4",
        "image": "mattress4.png",
        "headerTitle": "2 in 1 Plus Mattress",
        "shortDescriptionLine1":"Enjoy the perfect balance of resilience and",
        "shortDescriptionLine2":"plushness as you sink into this soft mattress",
        "headerDescription": "The 2-in-1 Plus Mattress – where superior bounce-back and ultra-soft material converge for the ultimate sleep experience. Enjoy the perfect balance of resilience and plushness as you sink into the embrace of this exceptional mattress. Experience nights of restful slumber and awaken refreshed, thanks to its unique combination of bounce and softness.",
        "detailTitle": "Mattress that keeps your night cool and give zero partner disturbance",
        "detailDescription": "Gentle support for your body at all points while keeping your spine in the same shape as a person with good standing position. It is made to pay special attention to shoulders, hips and lower back- the heaviest parts of the human body.",
        "features": [{
          "image": "contouring.svg",
          "titleLine1": "Superior",
          "titleLine2": "Bounce back"
        }, {
          "image": "spine.svg",
          "titleLine1": "Attention",
          "titleLine2": "to spine"
        },
        {
          "image": "img_frame_19_blue_gray_400_44x74.svg",
          "titleLine1": "Ultra soft",
          "titleLine2": "Material"
        },
        {
          "image": "pressure_relieve.svg",
          "titleLine1": "Pressure relieving",
          "titleLine2": "technology"
        },
        {
          "image": "deepsleep.svg",
          "titleLine1": "Dual Firm",
          "titleLine2": "Technology"
        }
        ],
        "images": ["twoinoneplus1.png", "twoinoneplus2.png", "twoinoneplus3.png", "twoinoneplus4.png"],
        "footerImage":"NA",
      },
      {
        "video":"2in1_plus_Lux.mp4",
        "image": "mattress5.png",
        "headerTitle": "2 in 1 Plus Luxury Mattress",
        "shortDescriptionLine1":"Advanced pressure relief technology, it cradles",
        "shortDescriptionLine2":"your body, ensuring a restful night's sleep",
        "headerDescription": "2-in-1 Plus Luxury Mattress – where innovation meets luxury. Featuring a high-resilience (HR) foam layer, this mattress provides the perfect balance of support and comfort. With advanced pressure relief technology, it cradles your body, ensuring a restful night's sleep. Elevate your sleep experience with the 2-in-1 Plus Luxury Mattress for the ultimate in support and pressure relief.",
        "detailTitle": "A memory foam collection for Luxurious and most comfortable environment",
        "detailDescription": "Introducing DURA Luxury by Durafit, a mattress designed for rejuvenating sleep. Featuring a blend of high density vital bond layer.",
        "features": [{
          "image": "img_frame_19_blue_gray_400_44x74.svg",
          "titleLine1": "Ultrasoft",
          "titleLine2": "Foam Material"
        }, {
          "image": "img_frame_19_44x64.png",
          "titleLine1": "Luxury knitted",
          "titleLine2": "Material"
        },
        {
          "image": "contouring.svg",
          "titleLine1": "HR foam",
          "titleLine2": "layer"
        },
        {
          "image": "vector.svg",
          "titleLine1": "Naturally",
          "titleLine2": "hypoallergic"
        },
        {
          "image": "pressure_relieve.svg",
          "titleLine1": "Pressure relieving",
          "titleLine2": "technology"
        }
        ],
        "images": ["twolux1.png", "twolux2.png"],
        "footerImage":"NA",
      },
      {
        "video":"Hybrid.mp4",
        "image": "mattress6.png",
        "headerTitle": "Hybrid Mattress",
        "shortDescriptionLine1":"Advanced pressure relief technology, it cradles",
        "shortDescriptionLine2":"your body, ensuring a restful night's sleep",
        "headerDescription": "Three-Zone Comfort Mattress – Discover affordable luxury with a breathable design that promotes a cooler, more comfortable sleep. This mattress features three distinct comfort zones that provide tailored support where you need it most, ensuring a relaxed and restorative night’s sleep. Enjoy the high-quality features of a premium mattress without the premium price.",
        "detailTitle": "Ergonomically designed for seven zone body support with firm & resilient technology",
        "detailDescription": "Experience unparalleled comfort and support with our hybrid mattress, meticulously crafted with a perfect blend of high density vital bond material for exceptional support and High resilience foam for optimal comfort. This innovation 3-zone mattress is designed to provide you with the ultimate sleeping experience, ensuring a perfect balance between support and luxury.",
        "features": [{
          "image": "spine.svg",
          "titleLine1": "3 zone",
          "titleLine2": "comfort"
        }, {
          "image": "img_frame_19_44x64.png",
          "titleLine1": "Breathable",
          "titleLine2": "Material"
        },
        {
          "image": "contouring.svg",
          "titleLine1": "Durable",
          "titleLine2": ""
        },
        {
          "image": "vector.svg",
          "titleLine1": "Naturally",
          "titleLine2": "hypoallergic"
        },
        {
          "image": "pressure_relieve.svg",
          "titleLine1": "Economic",
          "titleLine2": "range"
        }
        ],
        "images": ["hybrid1.png", "hybrid2.png", "hybrid3.png", "hybrid4.png"],
        "footerImage":"NA",
      },
      
    ],
    reasons: [
      {
        title: "Technology that is recommended by medical professionals",
        reason: "Experience the difference of a mattress recommended by healthcare  experts, ensuring your rest contributes to overall well-being. Incorporating the latest in sleep science, our  mattresses are crafted to support your body and enhance your sleep  quality, as validated by medical endorsements.",
        image: "mattress_upscale_1.png",
      },
      {
        title: "Fabric so breathable, your baby will love it",
        reason: "Featuring advanced breathable technology, our fabric ensures optimal air  circulation, keeping your baby cool and comfortable. Its innovative  design promotes a safer, hypoallergenic sleep environment for delicate skin",
        image: "mattress_upscale_2.png",
      },
      {
        title: "Naturally cool and airy feel for all weather conditions",
        reason: "Incorporating innovative cooling fabric technology, our mattresses not  only ensure a naturally cool and airy feel but also significantly  enhance sleep quality by maintaining an optimal sleeping temperature  throughout the night.",
        image: "mattress_upscale_3.png",
      }
    ],
    faqs: [
      {
        question: "Can a mattress be customized to size?",
        answer: "Something"
      },
      {
        question: "What is the 100-nights trial?",
        answer: "Something"
      },
      {
        question: "Is the mattress material safe for me?",
        answer: "Something"
      },
      {
        question: "How long is the warranty?",
        answer: "Something"
      }
    ]
  },
  "Cushioning":
  {
    productType: "Cushioning",
    image: "cushioning_header.png",
    headerLine1: "Comfort seating for your guests",
    headerLine2: "",
    description: "We believe that a great night's sleep starts with the perfect pillow, and that's exactly what we offer. Our pillows are designed to cradle your neck and head in gentle support, providing you with the sweetest dreams night after night with the softest materials. Say goodbye to restless nights and hello to the ultimate in relaxation.",
    products: [
      {
        "video":"NA",
        "image": "memoryc.png",
        "headerTitle": "Memory Cushion",
        "shortDescriptionLine1":"Pillows that cradle your head and neck for ",
        "shortDescriptionLine2":"unparalleled support and pressure relief",
        "headerDescription": "Experience personalized comfort with our Memory Foam Cushion. These cushion adapt to your back shape for unparalleled support and pressure relief. Enjoy a comfortable seating ensuring a reliving experience for your guest.",
        "detailTitle": "A blend of quality and comfort",
        "detailDescription": "The Bamboo fabric cover distinguishes it from others. It is super soft, anti-bacterial and anti-allergenic. Suitable for every type of sleeper and provides the right amount of support with the right amount of fuffiness just like Durafit Mattress. Presenting the best solution for your comfort sitting experience.",
        "features": [{
          "image": "contouring.svg",
          "titleLine1": "Contouring Support",
          "titleLine2": ""
        }, {
          "image": "pressure_relieve.svg",
          "titleLine1": "Pressure Relief",
          "titleLine2": ""
        },
        {
          "image": "img_frame_19.svg",
          "titleLine1": "Temperature Sensitivity",
          "titleLine2": ""
        },
        {
          "image": "vector.svg",
          "titleLine1": "Hypoallergenic",
          "titleLine2": ""
        }
        ],
        "images": ["memoryc1.png", "memoryp2.png"],
        "footerImage":"memoryc_footer.png",
      },
      {
        "video":"NA",
        "image": "latexc.png",
        "headerTitle": "Latex Cushion",
        "shortDescriptionLine1":"PCrafted from eco-friendly latex, these pillows ",
        "shortDescriptionLine2":"offer resilient support for spine alignment",
        "headerDescription": "Discover the natural comfort of Latex Foam Cushion. Crafted from eco-friendly latex, these cushions offer resilient support that promotes proper spine alignment. Enjoy a cool and refreshing experience and rest assured knowing that our latex pillows are built to last, providing you with comfortable support for years to come.",
        "detailTitle": "A blend of quality and comfort",
        "detailDescription": "The Bamboo fabric cover distinguishes it from others. It is super soft, anti-bacterial and anti-allergenic. Suitable for every type of sleeper and provides the right amount of support with the right amount of fuffiness just like Durafit Mattress. Presenting the best solution for your comfort sitting experience.",
        "features": [{
          "image": "img_frame_19_blue_gray_400.svg",
          "titleLine1": "Natural Material",
          "titleLine2": ""
        }, {
          "image": "contouring.svg",
          "titleLine1": "Supportive Comfort",
          "titleLine2": ""
        },
        {
          "image": "img_frame_19_44x64.png",
          "titleLine1": "Breathability",
          "titleLine2": ""
        },
        {
          "image": "img_frame_19_blue_gray_400_44x64.svg",
          "titleLine1": "Durability",
          "titleLine2": ""
        }
        ],
        "images": ["latexc1.png", "memoryp2.png"],
        "footerImage":"latexc_footer.png",
      },
      
    ],
    reasons: [
      {
        title: "Enhanced neck and spine alignment for improved posture",
        reason: "Alleviates pressure points and muscle tension, enhancing comfort  ensuring a quality, uninterrupted experience",
        image: "cushioning1.png",
      },
      {
        title: "Reduced tension and pressure",
        reason: "Featuring advanced breathable technology, our fabric ensures optimal air  circulation, keeping your baby cool and comfortable. Its innovative  design promotes a safer, hypoallergenic sleep environment for delicate skin",
        image: "cushioning2.png",
      },
      {
        title: "Optimal support adapting to individual postures",
        reason: "Adapts to individual sitting styles, providing tailored support for the back, maximizing comfort, and ensuring restorative seating",
        image: "cushioning3.png",
      }
    ],
    faqs: [
      {
        question: "What is the difference between two pillow types?",
        answer: ""
      },
      {
        question: "Does pillow also have 100-nights trial?",
        answer: ""
      },
      {
        question: "Is the material anti-allergenic?",
        answer: ""
      },
      {
        question: "How to clean the pillow?",
        answer: ""
      }
    ]
  },
  "Pillows":
  {
    productType: "Pillows",
    image: "up-in-the-clouds_1024x1024_3.png",
    headerLine1: "Pillows that Hug You Back",
    headerLine2: "",
    description: "We believe that a great night's sleep starts with the perfect pillow, and that's exactly what we offer. Our pillows are designed to cradle your neck and head in gentle support, providing you with the sweetest dreams night after night with the softest materials. Say goodbye to restless nights and hello to the ultimate in relaxation.",
    products: [
      {
        "video":"NA",
        "image": "pillow1.png",
        "headerTitle": "Memory Pillow",
        "headerDescription": "Experience personalized comfort with our Memory Foam Pillows. These pillows adapt to your unique shape, cradling your head and neck for unparalleled support and pressure relief. Enjoy a restful night's sleep with the perfect alignment and comfort provided by our memory foam pillows.",
        "shortDescriptionLine1":"Pillows that cradle your head and neck for",
        "shortDescriptionLine2":" unparalleled support and pressure relief",
        "detailTitle": "A blend of quality and comfort with our Dream Pillow",
        "detailDescription": "The Bamboo fabric cover distinguishes it from others. It is super soft, anti-bacterial and anti-allergenic. Suitable for every type of sleeper and provides the right amount of support with the right amount of fuffiness just like Durafit Mattress. Presenting the best solution for your tossing and turning nights.",
        "features": [{
          "image": "contouring.svg",
          "titleLine1": "Contouring Support",
          "titleLine2": ""
        }, {
          "image": "pressure_relieve.svg",
          "titleLine1": "Pressure Relief",
          "titleLine2": ""
        },
        {
          "image": "img_frame_19.svg",
          "titleLine1": "Temperature Sensitivity",
          "titleLine2": ""
        },
        {
          "image": "vector.svg",
          "titleLine1": "Hypoallergenic",
          "titleLine2": ""
        }
        ],
        "images": ["memoryp1.png", "memoryp2.png"],
        "footerImage":"memory_pillow_footer.png",
      },
      {
        "image": "pillow2.png",
        "headerTitle": "Latex Pillow",
        "headerDescription": "Discover the natural comfort of Latex Foam Pillows. Crafted from eco-friendly latex, these pillows offer resilient support that promotes proper spine alignment. Enjoy a cool and refreshing night's sleep, and rest assured knowing that our latex pillows are built to last, providing you with comfortable support for years to come.",
        "shortDescriptionLine1":"Crafted from eco-friendly latex, these pillows ",
        "shortDescriptionLine2":"offer resilient support for spine alignment",
        "detailTitle": "A blend of quality and comfort with our Dream Pillow",
        "detailDescription": "The Bamboo fabric cover distinguishes it from others. It is super soft, anti-bacterial and anti-allergenic. Suitable for every type of sleeper and provides the right amount of support with the right amount of fuffiness just like Durafit Mattress. Presenting the best solution for your tossing and turning nights.",
        "features": [{
          "image": "img_frame_19_blue_gray_400.svg",
          "titleLine1": "Natural Material",
          "titleLine2": ""
        }, {
          "image": "contouring.svg",
          "titleLine1": "Supportive Comfort",
          "titleLine2": ""
        },
        {
          "image": "img_frame_19_44x64.png",
          "titleLine1": "Breathability",
          "titleLine2": ""
        },
        {
          "image": "img_frame_19_blue_gray_400_44x64.svg",
          "titleLine1": "Durability",
          "titleLine2": ""
        }
        ],
        "images": ["latex1.png", "memoryp2.png"],
        "footerImage":"latex_footer.png",
        "video":"NA"
        
      },
    ],
    reasons: [
      {
        title: "Enhanced neck and spine alignment for improved posture",
        reason: "Supports proper posture by aligning the neck and spine, reducing the  risk of stiffness and pain, and promoting a therapeutic sleep  environment",
        image: "pillow_upscale_1.png",
      },
      {
        title: "Reduced tension and pressure in muscles for a restful sleep",
        reason: "Alleviates pressure points and muscle tension, enhancing sleep quality and ensuring a deeper, uninterrupted sleep cycle",
        image: "pillow_upscale_2.png",
      },
      {
        title: "Optimal support adapting to individual sleep positions",
        reason: "Adapts to individual sleeping styles, providing tailored support for the  head and neck, maximizing comfort, and ensuring restorative sleep",
        image: "pillow_upscale_3.png",
      }
    ],
    faqs: [
      {
        question: "What is the difference between two pillow types?",
        answer: "Something"
      },
      {
        question: "Does pillow also have 100-nights trial?",
        answer: "Something"
      },
      {
        question: "Is the material anti-allergenic?",
        answer: "Something"
      },
      {
        question: "How to clean the pillow?",
        answer: "Something"
      }
    ]
  }

}